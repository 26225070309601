@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');

* {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
    color: #bbbbbb;
}

body{
    font-family: 'Raleway', sans-serif;
    color: #bbbbbb;
}

img{
    max-width: 100%;
    height: auto;
}

ul, ol{
    list-style: none;
}

a {
    text-decoration: none;
    color:#73DEE6;
}

.container {
    width: 100%;
    height: 100%;
  }

  .main-container {
    width: 100%;
    background-color: #eeeeee;
    overflow: scroll !important;
    padding-top: 70px;
    padding-bottom: 48px;
}

.main-section {
    background-color:#ffffff;
    max-width: 920px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.banner{
    width: 100%;
    height: auto;
    padding: 25px 25px 0px 25px;
}

.content-area{
    width: 100%;
    height: auto;
    padding: 25px 25px 25px 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

@media(max-width: 880px) {
    .content-area{
        width: 100%;
        height: auto;
        padding: 25px 25px 25px 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.assinatura {
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}

.mb-7{
  margin-bottom: 7px;
}
