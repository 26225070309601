.capa-livro{
    width: 29%;
    height: auto;
}

.capa-livro .title{
    margin-top: 20px;
}

.capa-livro span{
    font-size: 14px;
}

.img-livro{
    width: 100%;
    height: auto;
}

.block-player-audio{
    width: 69%;
    height: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: #EDEBEB;
    padding: 25px;
}

.block-title-players{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.block-player-audio h3, .capa-livro h3{
    color: #176C78;
    text-align: left;
    font-weight: 700;
    font-size: 22px;
    padding: 5px 0px 5px 0px;
    margin: 0;
    border-bottom: solid 0px;;
}

.block-player-audio h4{
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    margin: 0;
}
#player-h4{
    margin-bottom: 25px;
}
.block-player-audio img{
    width: 63px;
    height: auto;
}

.players-box{
    max-height: 800px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}

.players-box::-webkit-scrollbar {
    display: none;
}

.player{
    padding: 15px 0px 15px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid;
    border-color: #EDEBEB;
}

.player button{
    width: 50px;
    height: auto;
    background-color: black;
    margin-right: 15px;
}

#biblioteca-full{
    width: 100%;
    padding-bottom: 25px;
    justify-content: space-between !important;
}

.livro-descricao{
    color: #000000;
    line-height: 1.5;
}

.player-estilos{
    border-radius: 0px;
    max-width: 48%;
    margin-left: 25px;
}

.titulo-audiopodcast{
    max-width: 40%;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    max-width: 48%;
    padding-right: 20px;
    line-height: 20px;
}

@media(max-width: 880px) {
    .capa-livro{
        width: 100% !important;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .img-livro{
        width: 40% !important;
        margin-bottom: 5px;
    }
    .block-player-audio{
        width: 100%;
    }
    .player{
        flex-direction: column;
    }
    .player-estilos{
        max-width: 100%;
        margin-left: 0px;
    }
    .titulo-audiopodcast{
        max-width: 100% !important;
        margin-bottom: 10px;
        padding-right: 0px;
    }

    button.voltar{
        display: block !important;
        position: absolute;
        top: 90px;
        right: 20px;
        background-color: #176C78;
        color: #EDEBEB;
        padding: 10px 15px;
        cursor: pointer;
        border: 0px;
        border-radius: 5px;
        transition: 0.2s;
    }

    button.voltar:hover{
        background-color: #1f828f;
    }
}