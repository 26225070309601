footer{
    width: 100%;
    background-color: #333333;
    position:fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
}

.footer{
    max-width: 920px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-size: 14px;
}

@media(max-width: 880px) {
    footer{
        height: 60px;
    }
    .footer {
        max-width: 100%;
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 2%;
        margin-bottom: 2%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}