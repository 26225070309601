.container-full{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-image: url(../../assets/img/background-login.jpg);
    opacity: 0.9;

}
.login-box {
    width: 90%;
    max-width: 600px;
    height: 350px;
    background-color:#176C78;
    opacity: 0.8;
    display: flex;
}

.container-logo {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.container-logo h3{
    font-size: 15px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 15px;
}

.container-logo .info{
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    line-height: 1.3;
}

.container-form {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.container-form .botoes{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
}

.container-form .botoes .login{
    width: 50%;
    padding: 10px;
    background-color: #176C78;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-form .botoes .cadastro{
    width: 50%;
    padding: 10px;
    background-color: #4B919B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-form .form{
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.container-form label{
    color:  #176C78;
    font-weight: bold;
}

.inputForm{
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #707070;
    width: 80%;
}

.container-form .inputForm input{
    border: none;
    width: 100%;
    color: #000000;
    height: 30px;
}

.container-form .esqueceu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 12px;
    color: #ffffff;
}

.button{
    width: 50%;
    color: #ffffff;
    background-color: #176C78;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
}

.button:hover{
    background-color:#4B919B;
    transition: 300ms,
}

.spinner{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner button{
    margin-right: 10px;
}

.div-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}

.button-contato{
    width: 48% ;
    color: #ffffff ;
    background-color: #07363c ;
    display: flex ;
    justify-content: center ;
    align-items: center ;
    height: 30px ;
    border: none ;
    border-radius: 5px ;
    margin-top: 5px ;
    cursor: pointer ;
}

.button-contato:hover, .button-whats:hover{
    background-color:#4B919B;
    transition: 300ms,
}

.button-whats{
    width: 48%;
    color: #ffffff;
    background-color: #25D366;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
}

@media(max-width:880px){
    .login-box {
        flex-direction: column;
        height: 80% !important;
    }
    .container-logo {
        width: 100% !important;
    }
    .container-logo img{
        width: 50% !important;
    }
    .container-form{
        width: 100% !important;
        justify-content: space-around;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
    }
    .container-form .form{
        width: 100%;
        height: 220px;
    }
}
