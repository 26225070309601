.container-full{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-image: url(../../assets/img/background-login.jpg);
    opacity: 0.9;

}
.login-box {
    width: 90%;
    max-width: 600px;
    height: 350px;
    background-color:#176C78;
    opacity: 0.8;
    display: flex;
}

.container-logo {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.container-logo .title{
    margin-top:10px;
    margin-bottom: 10px;
}

.container-logo p{
    font-size: 12px;
    text-align: justify;
}

.container-form {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.container-form .botoes{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
}

.container-form .botoes .login2{
    width: 50%;
    padding: 10px;
    background-color: #4B919B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-form .botoes .cadastro2{
    width: 50%;
    padding: 10px;
    background-color: #176C78;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-form .form{
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.container-form label{
    color:  #176C78;
}

.inputForm{
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #707070;
    width: 80%;
}

.container-form .inputForm input{
    border: none;
}

.container-form .esqueceu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 12px;
}

.button{
    width: 50%;
    color: #ffffff;
    background-color: #176C78;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
}

.button:hover{
    background-color:#4B919B;
}