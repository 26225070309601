.capa-livro{
    width: 29%;
    height: auto;
}

.capa-livro .title{
    margin-top: 20px;
}

.capa-livro span{
    font-size: 14px;
}

.img-livro{
    width: 100%;
    height: auto;
}

.block-player{
    width: 69%;
    height: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: #EDEBEB;
    padding: 25px;
    position: relative;
}

.block-player h3, .capa-livro h3{
    color: #176C78;
    text-align: left;
    font-weight: 700;
    font-size: 22px;
    padding: 5px 0px 5px 0px;
    margin: 0;
    border-bottom: solid 0px;;
}

.block-player h4{
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    margin: 0;
}
#player-h4{
    margin-bottom: 25px;
}
.block-player img{
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    width: 63px;
    height: auto;
    margin: 25px;
}

.players{
    position: relative;
    max-height: 800px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}

.players::-webkit-scrollbar {
    display: none;
}

.player{
    padding: 15px 0px 15px 0px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid;
    border-color: #EDEBEB;
}

.player button{
    width: 50px;
    height: auto;
    background-color: black;
    margin-right: 15px;
}

.player span{
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    max-width: 48%;
    padding-right: 20px;
    line-height: 20px;
}

#biblioteca-full{
    width: 100%;
    padding-bottom: 25px;
    justify-content: space-between !important;
}

.livro-descricao{
    color: #000000;
    line-height: 1.5;
}

.player-estilos{
    border-radius: 0px;
}

.podcast-title{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.podcast-title h4{
    color: #000000;
    font-weight: 700;
    font-size: 16px;
    padding-right: 20px;
    line-height: 28px;
}

@media(max-width:880px){
    .block-player{
        width: 100%;
    }
}