.banner p{
    color: #000000;
    text-align: justify;
    margin-top: 5px;
    line-height: normal;
}

.editors-list{
    width: 69%;
    height: auto;
    border-style: solid;
    border-width: 1px;
    border-color: #EDEBEB;
}

.podcast-list{
    width: 29%;
    height: auto;
    border-style: solid;
    border-width: 1px;
    border-color: #EDEBEB;
}

.editors-list h3, .podcast-list h3{
    color: #176C78;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    padding: 20px 20px 20px 20px;
    margin: 0px 20px 0px 20px;
    border-bottom: solid 1px;
    border-color: #EDEBEB;
}

.bloque-editor, .bloque-podcast{
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-top:5px;
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 15px 0px;
    border-bottom: 1px solid;
    border-color: #EDEBEB;
    ;
}

.texto-editor{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.texto-editor h3, .texto-podcast h3{
    color: #176C78;
    text-align: right;
    font-weight: 700;
    font-size: 18px;
    padding: 0;
    margin: 0;
    border-bottom: solid 0px;;
}

.texto-editor h5 {
    color: #73DEE6;
    text-align: right;
    font-weight: 500;
    font-size: 15px;
    padding: 0;
    margin: 0;
}

.texto-editor h4 {
    color: #000000;
    font-weight: 500;
    font-size: 17px;
}

.livros-editor{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.livros-editor img{
    padding-left: 10px;
    width: 78px;
    height: 104px;
}

.texto-podcast{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.texto-podcast h3{
    color: #176C78;
    text-align: left;
    font-weight: 600;
    font-size: 15px;
    padding: 0;
    margin: 0;
}

.texto-podcast h5{
    color: #73DEE6;
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    padding: 0;
    margin: 0;
}

.texto-podcast h4{
    color: #666666;
    font-weight: 500;
    font-size: 13px;
    padding: 0;
    margin: 0;
}

.texto-podcast h4:hover{
    color: #73DEE6;
    font-weight: 500;
    font-size: 13px;
    padding: 0;
    margin: 0;
}

.logo-dashboard{
    width: 100px;
    height: 100%;
}

.texto-editor h4:hover {
    color: #176C78;
}

.img-destacado-vazio{
    width: 78px;
    height: 104;
}

.logo-podcast{
    width: 70px;
    height: 70px;
}

@media(max-width: 880px) {
    .editors-list{
        width: 100%;
        height: auto;
        border-style: solid;
        border-width: 1px;
        border-color: #EDEBEB;
        margin-bottom: 20px;
    }

    .podcast-list{
        width: 100%;
        height: auto;
        border-style: solid;
        border-width: 1px;
        border-color: #EDEBEB;
    }

    .livros-editor{
        display: none;
    }

    .texto-podcast{
        align-items: flex-end;
    }
    .texto-podcast h3{
        text-align: right;
    }
}