header{
    width: 100%;
    background-color: #176C78;
    position:fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 70px;
    z-index: 4;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:#176C78;
    height: 70px;
    padding: 0px;
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
}

.header img{
    width: 150px;
    height: auto;
    padding-top: 5px;
}
.menu{
    display: flex;
}

.menu li{
    padding-left: 20px;
    color: #73DEE6;
    font-size: 14px;
}

.menu li a:hover{
    color: #FFFFFF;
}

.header .profile a img{
    width: 50px;
    height: auto;
    display: block;
    padding-top: 0px;
}

.logout{
    cursor: pointer;
    background-color: #176C78 !important;
    color:#73DEE6;
    border: none;
}

.logout:hover{
    color:#FFFFFF;
}
.menu-responsive{
    display: none;
}

@media(max-width: 880px) {
    .header{
        display: flex;
        max-width: 100%;
        margin-left: 25px;
        margin-right: 25px;
    }
    .menu{
        display: none;
    } 
    .header img{
        width: 120px;
    }
    .menu-responsive{
        display: block;
    }
}