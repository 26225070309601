.editora-description{
    width: 20%;
    height: auto;
    min-height: 450px;
}

.editora-description img{
    width: 100%;
    height: auto;
    padding: 0px 10px 20px 0px;
}

.editora-title{
    color: #999999;
    font-weight: 600;
    font-size: 24px;
    padding: 0;
    text-align: left;
    margin-bottom: 20px;
}

.editora-description-text{
    color: #999999;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 20px;
    line-height: 18px;
}

.editora-description-span{
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    margin-bottom: 5px;
}

.biblioteca{
    width: 78%;
    height: auto;
    border-style: solid;
    border-width: 1px;
    border-color: #EDEBEB;
    position: relative;
}

.biblioteca h3{
    color: #176C78;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    padding: 20px 20px 20px 20px;
    margin: 0px 20px 0px 20px;
    border-bottom: solid 1px;
    border-color: #EDEBEB;
}

.block-livros{
    height: auto;
    margin-left: 20px;
    margin-right: 10px;
    margin-top:5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0px 15px 0px;
    border-bottom: 1px solid;
    border-color: #EDEBEB;
}

.books{
    margin-right: 14px;
    margin-bottom: 10px;
    width: 115px;
    height: 160px;
}

@media(max-width: 880px) {
    .editora-description{
        width: 100% !important;
        display: flex;
        min-height: max-content;
        justify-content: space-between;
    }
    .editora-description img{
        width: 150px !important;
        height: 160px !important;
        padding-right: 20px !important;
    }
    .editora-description h4, .editora-description-span, .editora-description-text{
        text-align: right !important;
    }
    .biblioteca{
        width: 100%;
    }

    .biblioteca h3{
        text-align: left;
        padding: 20px 20px 20px 0px;
    }

    .biblioteca button.button-dashboard{
        display: block !important;
        position: absolute;
        top: 15px;
        right: 20px;
        background-color: #176C78;
        color: #EDEBEB;
        padding: 10px 15px;
        cursor: pointer;
        border: 0px;
        border-radius: 5px;
        transition: 0.2s;
    }

    .books{
        width: 78px;
        height: auto;
        margin-right: 10px;
    }
    .editora-text-block{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
}