.editora-description{
    width: 20%;
    height: auto;
}

.editora-description img{
    width: 100%;
    height: auto;
    padding: 0px 10px 20px 0px;
}

.editora-description h4{
    color: #000000;
    font-weight: 600;
    font-size: 24px;
    padding: 0;
    text-align: left;
    margin-bottom: 20px;
}

.editora-description p{
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 20px;
}

.editora-description span{
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 20px;
}


.biblioteca-pod{
    width: 100%;
    height: auto;
    border-style: solid;
    border-width: 1px;
    border-color: #EDEBEB;
}

.biblioteca-pod h3{
    color: #176C78;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    padding: 20px 20px 20px 20px;
    margin: 0px 20px 0px 20px;
    border-bottom: solid 1px;
    border-color: #EDEBEB;
}

.block-livros-pod{
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-top:5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0px 15px 0px;
    border-bottom: 1px solid;
    border-color: #EDEBEB;
}

.books-pod{
    margin-bottom: 5px;
    width: 200px;
    height: 150px;
}

.name-podcast{
    color: #176C78;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    width:200px;
}

.block-podcast-img{
    margin-bottom: 10px;
}

@media(max-width: 880px){
    .books-pod{
        margin-bottom: 5px;
        width: 100%;
        height: auto;
    }
    .name-podcast{
        width:100%;
    }
}